import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import styled from 'styled-components';
import CoverStartDate from 'components/CoverStartDate';
import { PanelWithActionLinkWithMargins } from 'components/CoverStartDate/styles';
import JointPolicyholderForm from 'forms/JointPolicyholderForm';
import { RemoveButtonContainer } from 'forms/JointPolicyholderForm/styles';

export const StyledJointPolicyholderForm = styled(JointPolicyholderForm)`
  ${RemoveButtonContainer} {
    display: flex;

    ${mediaQuery.tabletLandscape`
      justify-content: flex-end;
    `}
  }
`;

export const StyledCoverStartDate = styled(CoverStartDate)`
  ${PanelWithActionLinkWithMargins} {
    border: solid 1px ${colors.core01};
    box-shadow: none;
    margin-bottom: 0;
  }
`;
