import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import useKeepingYouInformedQuestions from 'forms/KeepingYouInformedForm/questions';
import { ProductId } from 'helpers/businessConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { KeepingYouInformedSectionProps } from './KeepingYouInformedSection';
import { getAnswerText } from './useDeclarationSectionProps';

type KeepingYouInformedSectionContent = {
  csPetAboutYouAndYourPet: {
    keeping_you_informed_section_heading: string;
  };
  csPetKeepingYouInformedQuestions: {
    keep_informed_question: {
      privacy_notice: string | undefined;
    };
  };
  csPetGlobalConfig: {
    boolean_answer_text: {
      yes: string;
      no: string;
    };
  };
};

const query = graphql`
  query {
    csPetAboutYouAndYourPet {
      keeping_you_informed_section_heading
    }
    csPetKeepingYouInformedQuestions {
      keep_informed_question {
        privacy_notice
      }
    }
    csPetGlobalConfig {
      boolean_answer_text {
        yes
        no
      }
    }
  }
`;

export const useKeepingYouInformedSectionProps = (
  keepInformedAnswer: boolean | undefined
): KeepingYouInformedSectionProps | undefined => {
  const {
    csPetAboutYouAndYourPet: { keeping_you_informed_section_heading },
    csPetKeepingYouInformedQuestions: {
      keep_informed_question: { privacy_notice },
    },
    csPetGlobalConfig: { boolean_answer_text },
  } = useStaticQuery<KeepingYouInformedSectionContent>(query);

  const theme = useTheme();
  const quote = useCurrentQuote();

  const keepingYouInformedQuestion = useKeepingYouInformedQuestions().keepingYouInformed
    .questionText;
  const keepingYouInformedAnswer = getAnswerText(keepInformedAnswer, boolean_answer_text);

  if (quote?.productId === ProductId.DIRECT) {
    return undefined;
  }

  return {
    theme,
    sectionHeading: keeping_you_informed_section_heading,
    privacyNotice: privacy_notice,
    keepingYouInformedQuestion,
    keepingYouInformedAnswer,
  };
};
