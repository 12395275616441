import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import useProductFeatureTableContent from 'components/QuoteSummary/ProductFeaturesTable/content';
import { getPetTypesBeingInsured } from 'helpers/getPetTypesBeingInsured';
import { getQuotePlaceholdersWithProductOption } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { ProductFeatureSectionProps } from './ProductFeatureSection';

type ProductSectionContent = {
  csPetSummaryOfCover: {
    features_info: {
      included_features_heading: string;
      not_included_features_heading: string;
    };
  };
};

const query = graphql`
  query {
    csPetSummaryOfCover {
      features_info {
        included_features_heading
        not_included_features_heading
      }
    }
  }
`;
export const useProductFeatureSectionProps = (): ProductFeatureSectionProps => {
  const {
    csPetSummaryOfCover: { features_info },
  } = useStaticQuery<ProductSectionContent>(query);

  const {
    csPetSummaryOfCover: {
      features_info: { product_feature },
    },
  } = useProductFeatureTableContent();
  const quote = useCurrentQuote();
  const insuredPetTypes = getPetTypesBeingInsured(quote?.petInfos || []);
  const product = getProductFromQuoteOptions(quote.quoteOptions) || Product.Essential;
  const displayedFeaturesForProduct = product_feature.filter(
    (feature) =>
      feature.display_on_products_selector.product_selector.includes(product) &&
      (feature.applicable_pets.includes('All cases') ||
        feature.applicable_pets.includes(insuredPetTypes))
  );
  const includedFeaturesForProduct = displayedFeaturesForProduct.filter((feature) =>
    feature.included_with_products_selector.product_selector.includes(product)
  );
  const notIncludedFeaturesForProduct = displayedFeaturesForProduct.filter(
    (feature) =>
      !feature.included_with_products_selector.product_selector.includes(product)
  );
  const theme = useTheme();
  const replaceProductPlaceholders = replacePlaceholdersPlainText(
    getQuotePlaceholdersWithProductOption(product),
    quote
  );
  const notIncludedFeatures = features_info.not_included_features_heading;
  const includedFeatures = features_info.included_features_heading;
  return {
    replaceProductPlaceholders,
    includedFeaturesForProduct,
    notIncludedFeaturesForProduct,
    includedFeatures,
    notIncludedFeatures,
    product,
    theme,
  };
};
