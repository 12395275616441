import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { useSummaryText } from 'components/QuoteSummary/SummaryOfYourNeeds/SummaryOfYourNeedsTextSection/useSummaryText';
import { petPlaceholders } from 'helpers/placeholders/petPlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { SummaryOfNeedsSectionProps } from './SummaryOfNeedsSection';

type SummaryOfNeedsSectionContent = {
  csPetQuoteSummaryOfYourNeedsV2: {
    text_summary: {
      per_pet_excess_statement: string;
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeedsV2 {
      text_summary {
        per_pet_excess_statement
      }
    }
  }
`;

export const useSummaryOfNeedsSectionProps = (): SummaryOfNeedsSectionProps => {
  const {
    csPetQuoteSummaryOfYourNeedsV2: {
      text_summary: { per_pet_excess_statement },
    },
  } = useStaticQuery<SummaryOfNeedsSectionContent>(query);

  const summaryText = useSummaryText();
  const quote = useCurrentQuote();
  const theme = useTheme();

  const substituteQuotePlacholders = replacePlaceholdersRichText(
    quotePlaceholders,
    quote
  );

  const substituteExcessStatementPetPlaceholders = (pet: QuoteResponsePetInfo): string =>
    replacePlaceholdersRichText(petPlaceholders, pet)(per_pet_excess_statement);

  const excessStatements = quote.petInfos?.map((pet) =>
    substituteExcessStatementPetPlaceholders(pet)
  );

  return {
    theme,
    textBeforeStatement: summaryText
      ? substituteQuotePlacholders(summaryText.text_before_statement)
      : undefined,
    textAfterStatement: summaryText
      ? substituteQuotePlacholders(summaryText.text_after_statement)
      : undefined,
    excessStatements,
  };
};
