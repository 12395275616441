import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { generateKeyForElement } from '@rsa-digital/evo-shared-components/helpers/elementKeyHelper';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import RichTextWithModal from 'components/RichTextWithModal';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import useAggregatorBrandDetails from 'helpers/useAggregatorBrandDetails';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import {
  AssumptionsAgreement,
  useAssumptionsAgreement,
} from 'state/formData/assumptionsAgreement';
import useAssumptions from './assumptions';
import {
  AggregatorList,
  AggregatorListItem,
  AssumptionsHeadingWrapper,
  AssumptionsQuestionField,
  EditButtonIcon,
  EditButtonLink,
  EditDetails,
  EditDetailsText,
  ImageWithFixedHeight,
  ImageWrapper,
  StyledGridItem,
  StyledSectionHeading,
  Subheading,
} from './styles';

type AggregatorAssumptionsSectionProps = {
  sectionHeadingId: string;
  getError: FieldFunction<AssumptionsAgreement, string | undefined>;
  showValidation: FieldFunction<AssumptionsAgreement, void>;
};

type CsAggregators = {
  csPetAggregators: {
    assumptions: {
      section_heading: string;
      subheading: string;
      confirmation_text: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
      please_edit_details_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAggregators {
      assumptions {
        section_heading
        subheading
        confirmation_text
        edit_button_text
        edit_button_screenreader_text
        please_edit_details_text
      }
    }
  }
`;

const AggregatorAssumptionsSection: React.FC<AggregatorAssumptionsSectionProps> = ({
  sectionHeadingId,
  getError,
  showValidation,
}) => {
  const {
    csPetAggregators: {
      assumptions: {
        section_heading,
        subheading,
        confirmation_text,
        edit_button_text,
        edit_button_screenreader_text,
        please_edit_details_text,
      },
    },
  } = useStaticQuery<CsAggregators>(query);

  const assumptions = useAssumptions();
  const [assumptionsAgreement, updateAssumptionsAgreement] = useAssumptionsAgreement();

  const quote = useCurrentQuote();
  const aggregatorBrandDetails = useAggregatorBrandDetails(quote.productId);

  /* istanbul ignore if */
  if (!assumptions) {
    return null;
  }

  return (
    <>
      <AssumptionsHeadingWrapper>
        <StyledSectionHeading heading={section_heading} id={sectionHeadingId} />
        {aggregatorBrandDetails?.logo && (
          <ImageWrapper data-cy="aggregator-logo">
            <ImageWithFixedHeight image={aggregatorBrandDetails.logo} />
          </ImageWrapper>
        )}
      </AssumptionsHeadingWrapper>
      <Grid alignLeft>
        <StyledGridItem desktop={8} tabletLandscape={8}>
          {subheading && <Subheading>{subheading}</Subheading>}
          <AggregatorList>
            {assumptions.assumptions.map((assumption) => (
              <AggregatorListItem key={generateKeyForElement(assumption)}>
                {assumption.displayText && (
                  <RichTextWithModal
                    html={assumption.displayText}
                    pageTitle={PageTitle.CheckYourDetails}
                    aria-live="assertive"
                  />
                )}
              </AggregatorListItem>
            ))}
          </AggregatorList>
          <EditDetails>
            <EditDetailsText> {please_edit_details_text}</EditDetailsText>
            <EditButtonLink
              aria-label={edit_button_screenreader_text}
              onClick={() => {
                trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit assumptions');
                navigate(
                  `${quoteAndBuyRoutes.aboutYouAndYourPet}#${assumptions.fieldIdOfFirstAssumption}`
                );
              }}>
              <EditButtonIcon name="edit" size="small" />
              {edit_button_text}
            </EditButtonLink>
          </EditDetails>
        </StyledGridItem>
      </Grid>
      <Grid alignLeft>
        <StyledGridItem desktop={10} tabletLandscape={10} tabletPortrait={10}>
          <AssumptionsQuestionField
            question={{ questionText: confirmation_text }}
            errorText={getError('assumptionsAgreed')}>
            <BooleanRadioInput
              id="assumptionsAgreed"
              value={assumptionsAgreement.assumptionsAgreed}
              onChange={(value) => {
                updateAssumptionsAgreement({ assumptionsAgreed: value });
                showValidation('assumptionsAgreed');
              }}
            />
          </AssumptionsQuestionField>
        </StyledGridItem>
      </Grid>
    </>
  );
};

export default AggregatorAssumptionsSection;
