import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  regularFontWeight,
  semiBoldFontWeight,
} from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import { Heading } from 'forms/SectionHeading/styles';

export const DetailGroupSectionContainer = styled.section`
  background-color: ${colors.core02};
  border: solid 1px ${colors.core01};
  padding: ${spacing(3)} ${spacing(2)};
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(6)} ${spacing(4)} ${spacing(3)};
  `}

  & #coverStartDate {
    margin: 0 ${spacing(1)};

    ${mediaQuery.tabletLandscape`
      margin: 0 ${spacing(1.5)};
    `}
  }

  & #coverStartDate-container {
    margin-bottom: 0;
  }
`;

export const StyledHeading = styled(Heading)`
  padding: ${spacing(3)} 0;
  margin-bottom: ${spacing(3)};
  border-bottom: solid 1px ${colors.neutral04};

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(4)} 0;
    margin-bottom: ${spacing(2)};
  `}
`;

export const UnorderedListWithoutBullets = styled.ul`
  text-align: left;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListItemWithMargins = styled.li`
  padding: ${spacing(3)} 0;
  
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(2)} 0;
  `}

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(3)} 0;
  `}

  border-top: solid 1px ${colors.neutral04};
`;

export const DetailSpan = styled.span`
  ${fonts.paragraph};

  ${mediaQuery.tabletPortrait`
    width: auto;
  `}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge};
  `}
`;

export const DetailSpanButton = styled(DetailSpan)`
  width: 30%;

  ${mediaQuery.tabletPortrait`
    width: 20%;
  `}
`;

export const DetailSpanHeading = styled(DetailSpan)`
  width: 70%;

  ${mediaQuery.tabletPortrait`
    width: 80%;
  `}
`;

export const DetailValue = styled(DetailSpan)`
  & {
    font-weight: ${semiBoldFontWeight};
  }
  margin-left: ${spacing(1)};
`;

export const DetailLabel = styled(DetailSpan)`
  font-weight: ${regularFontWeight};
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${spacing(6)} 0 ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}
`;

export const DetailHeading = styled(Heading)`
  font-weight: ${semiBoldFontWeight};
`;

export const EditButtonLink = styled(ButtonLink)`
  && {
    ${fonts.paragraph}
    & {
      font-weight: ${semiBoldFontWeight};
      && {
        & {
          text-decoration: none;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
        }
      }
    }

    ${mediaQuery.tabletPortrait`
    margin-left: auto;
    ${fonts.paragraphLarge}
      & {
        font-weight: ${semiBoldFontWeight};
        
      }  
    `}
  }
  margin-left: 0;
  border: 2px solid ${colors.core01};
  padding: ${spacing(1)} ${spacing(3)};

  text-align: left;
  white-space: nowrap;

  &:hover {
    border-color: transparent;
    box-shadow: inset 0 0 0 2px ${colors.core01};
  }
`;

export const EditButtonIcon = styled(Icon)`
  margin-right: ${spacing(1)};
`;

export const LabelExplanation = styled(RichText)`
  ${fonts.paragraphSmall};
  ${mediaQuery.tabletLandscape`
    ${fonts.paragraph};
  `}
  ${mediaQuery.tabletPortrait`
      width: 50%;
  `}
`;

export const LabelExplanationWrapper = styled.div`
   {
    flex-basis: 100%;
  }
`;

export const SectionWithBorder = styled.section`
  border: 1px solid ${colors.neutral01};
  padding: ${spacing(0)} ${spacing(2)} ${spacing(3)};
  margin: ${spacing(5)} 0;

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(3)} 0;
  `}

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(0)} ${spacing(4)} ${spacing(4)};
  `}
`;
