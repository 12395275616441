import Image from '@rsa-digital/evo-shared-components/components/Image';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const ImageWithFixedHeight = styled(Image)`
  height: ${spacing(4)};
  width: auto;

  margin: ${spacing(4)} 0 ${spacing(6)};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(2)} 0 0;
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
  `}
`;

export const EditInfoText = styled(RichTextWithModal)`
  margin: 0 0 ${spacing(4)};

  p {
    ${fonts.paragraph}

    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge}
    `}
  }
`;

export const QuoteReferenceWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const BoldParagraphLargeSpan = styled.span`
  ${fonts.paragraph}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge}
  `}
  
  & {
    font-weight: ${semiBoldFontWeight};
  }
`;

export const ParagraphLargeSpan = styled.span`
  ${fonts.paragraph}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge}
  `}
`;

export const StyledDeclarationSectionContainer = styled.section`
  margin-top: ${spacing(6)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(1)};
  `}
`;
export const SectionWithBorder = styled.section`
  border: 1px solid ${colors.neutral01};
  padding: ${spacing(0)} ${spacing(2)} ${spacing(1)};

  margin: ${spacing(3)} 0;
  ${mediaQuery.tabletLandscape`
    padding: ${spacing(0)} ${spacing(4)} ${spacing(2)};
  `}
`;
