import { Link } from 'components/PolicyDocuments';
import { CsPlaceholders } from './replaceCsPlaceholders';

export type PolicyDocumentsPlaceholderData = {
  productInformationDocument: Link | undefined;
};

export const policyDocumentsPlaceholders: CsPlaceholders<PolicyDocumentsPlaceholderData> = {
  insuranceProductInformationDocument: {
    getSubstitutionText: (placeholders) =>
      placeholders.productInformationDocument?.url || '',
    isPii: false,
  },
};
