import { graphql, useStaticQuery } from 'gatsby';
import { trimEnd } from 'lodash';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsCoverLevel = {
  vet_fees_limit_text: string;
  treatment_period_text: string;
};

type CsCoverLevelSectionProps = {
  csPetCheckYourDetailsDetailsSectionV2: {
    your_cover_level: {
      cover_type: string;
      singlepet_vet_fees_limit: string;
      multipet_vet_fees_limit: string;
      treatment_period: string;
      essential_cover: CsCoverLevel;
      standard_cover: CsCoverLevel;
      plus_cover: CsCoverLevel;
      premier_cover: CsCoverLevel;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      your_cover_level {
        cover_type
        singlepet_vet_fees_limit
        multipet_vet_fees_limit
        treatment_period
        essential_cover {
          vet_fees_limit_text
          treatment_period_text
        }
        standard_cover {
          vet_fees_limit_text
          treatment_period_text
        }
        plus_cover {
          vet_fees_limit_text
          treatment_period_text
        }
        premier_cover {
          vet_fees_limit_text
          treatment_period_text
        }
      }
    }
  }
`;

const useCoverLevelSection = (): Detail[] => {
  const {
    csPetCheckYourDetailsDetailsSectionV2: {
      your_cover_level: {
        cover_type,
        singlepet_vet_fees_limit,
        multipet_vet_fees_limit,
        treatment_period,
        essential_cover,
        standard_cover,
        plus_cover,
        premier_cover,
      },
    },
  } = useStaticQuery<CsCoverLevelSectionProps>(query);
  const quote = useCurrentQuote();
  const petInfos = quote?.petInfos ?? undefined;
  if (petInfos === undefined) {
    return [];
  }
  const isMultipet = petInfos?.length !== undefined && petInfos?.length > 1;
  const product = getProductFromQuoteOptions(quote.quoteOptions);

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  const getCoverLevel = (): CsCoverLevel | undefined => {
    switch (product) {
      case Product.Essential: {
        return essential_cover;
      }
      case Product.Standard: {
        return standard_cover;
      }
      case Product.Plus: {
        return plus_cover;
      }
      case Product.Premier: {
        return premier_cover;
      }
      default: {
        return undefined;
      }
    }
  };

  const coverLevel = getCoverLevel();
  const vetFeesLimit = coverLevel ? coverLevel.vet_fees_limit_text : '';
  const vetFeesLimitLabel = isMultipet
    ? multipet_vet_fees_limit
    : singlepet_vet_fees_limit;
  const treatmentPeriodText = coverLevel ? coverLevel.treatment_period_text : '';
  return [
    createDetail(cover_type, product),
    createDetail(
      petNameReplacer(trimEnd(petInfos[0].petName), vetFeesLimitLabel),
      plainTextPlaceholderReplacer(vetFeesLimit)
    ),

    createDetail(treatment_period, treatmentPeriodText),
  ];
};

export default useCoverLevelSection;
