import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const ContactDetailsSectionContainer = styled.section`
  border: 1px solid ${colors.neutral01};
  background-color: ${colors.core02};
  padding: ${spacing(3)} ${spacing(2)} 0;
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(5)} ${spacing(4)} ${spacing(1.125)};
  `}
`;

export const StyledFlexibleWidthDivider = styled(FlexibleWidthDivider)`
  margin: ${spacing(3)} 0 ${spacing(3.5)};
  ${mediaQuery.tabletLandscape`
    margin: ${spacing(4)} 0 ${spacing(3)};
  `}
`;
