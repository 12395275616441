import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { GridItemWrapper } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import Image from '@rsa-digital/evo-shared-components/components/Image';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import {
  unorderedListItemStyling,
  unorderedListStyling,
} from '@rsa-digital/evo-shared-components/components/RichText/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing, {
  spacingWithBorder,
} from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import SectionHeading from 'forms/SectionHeading';

export const StyledSectionHeading = styled(SectionHeading)`
  margin: ${spacing(2)} 0 ${spacing(2)};
  flex: 1 100%;
  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(4)} 0 ${spacing(4)};
  `}

  h2 {
    font-weight: ${semiBoldFontWeight};
  }
`;

export const Subheading = styled.div`
  ${fonts.paragraph}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge};
  `}
`;

export const AggregatorList = styled.ul`
  ${unorderedListStyling}
  max-width: 530px;
  ${fonts.paragraph}
  & {
    margin: 0;
  }

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge};
  `}
`;

export const AggregatorListItem = styled.li`
  ${unorderedListItemStyling}
  &&:empty {
    display: none;
  }
`;

export const AssumptionsQuestionField = styled(QuestionField)`
  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacingWithBorder(3, 1)};
  `}
  && {
    ${GridItemWrapper} {
      width: calc(80% - ${spacing(4)});
    }
  }
`;

export const EditButtonIcon = styled(Icon)`
  margin-right: ${spacing(1)};
`;

export const EditButtonLink = styled(ButtonLink)`
  margin-left: 0;
  border: 2px solid ${colors.core01};
  padding: ${spacing(1)} ${spacing(3)};

  text-align: left;
  white-space: nowrap;
  && {
    padding-top: ${spacing(2)};
    display: block;
    text-decoration: none;
    ${fonts.paragraph};

    & {
      font-weight: ${semiBoldFontWeight};
      && {
        & {
          text-decoration: none;
        }
      }
    }

    ${mediaQuery.tabletPortrait`
      padding: ${spacing(1)} ${spacing(2)};
      margin-left: auto;
    `}

    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge};

      & {
        font-weight: ${semiBoldFontWeight};
      }
    `}
  }
  &:hover {
    border-color: transparent;
    box-shadow: inset 0 0 0 2px ${colors.core01};
  }
`;

export const EditDetailsText = styled.p`
  ${fonts.paragraph};
  display: inline-block;
  margin: 0;

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge};
  `}
`;

export const EditDetails = styled.div`
  margin: ${spacing(4)} 0;
  display: block;
  ${mediaQuery.tabletLandscape`
    display: flex;
  `}
`;

export const ImageWithFixedHeight = styled(Image)`
  height: ${spacing(4)};
  width: auto;
`;

export const AssumptionsHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.neutral03};
  margin-bottom: ${spacing(2)};
  ${mediaQuery.tabletPortrait`
    flex-direction: row;
 `};
`;

export const ImageWrapper = styled.div`
  margin: ${spacing(1)} 0 ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(6)} 0 ${spacing(4)};
 `}

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(6)} 0 ${spacing(4)};
 `}
`;

export const StyledGridItem = styled(GridItem)`
  width: calc(100% - ${spacing(4)});
`;
