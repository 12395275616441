import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { trimEnd } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import useQuoteReference from 'components/CheckYourDetails/DetailsSection/useQuoteReference';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import CheckContactDetailsForm from 'forms/CheckContactDetailsForm';
import { areDateValuesEqual } from 'helpers/dateHelpers';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import {
  BoldParagraphLargeSpan,
  ParagraphLargeSpan,
  QuoteReferenceWrapper,
} from 'pages/pet/check-your-details/styles';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { useJointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { initialDateValue } from 'state/formData/shared/dateValue';
import DetailGroupSection, { Detail } from './DetailGroupSection';
import { DetailGroupSectionContainer, StyledHeading } from './DetailGroupSection/styles';
import { StyledCoverStartDate, StyledJointPolicyholderForm } from './styles';
import useAboutYouSection from './useAboutYouSection';
import useAdditionalInformationSection from './useAdditionalInformationSection';
import useCoverLevelSection from './useCoverLevelSection';
import { createDetail } from './useCreateDetail';
import useExcessSections from './useExcessSections';
import usePetSections from './usePetSections';

type DetailsSectionProps = {
  currentQuote: CurrentQuote;
  getError: FieldFunction<FormDetails, string | undefined>;
  showValidation: FieldFunction<FormDetails, void>;
};

type DetailsSectionCsProps = {
  csPetCheckYourDetailsDetailsSectionV2: {
    about_you: {
      about_you_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    about_your_joint_policyholder: {
      about_your_joint_policyholder_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    about_your_pet: {
      about_your_pet_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    additional_information: {
      additional_information_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    excess: {
      excess_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    your_cover_dates: {
      your_cover_dates_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    your_cover_level: {
      your_cover_level_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
  };
  csPetAboutYouAndYourPet: {
    joint_policyholder_banner: {
      body_text: string;
      button_text: string;
      button_aria_label: string;
      icon: {
        icon_code: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      about_you {
        about_you_heading
        edit_button_text
        edit_button_screenreader_text
      }
      about_your_joint_policyholder {
        about_your_joint_policyholder_heading
        edit_button_text
        edit_button_screenreader_text
      }
      about_your_pet {
        about_your_pet_heading
        edit_button_text
        edit_button_screenreader_text
      }
      additional_information {
        additional_information_heading
        edit_button_text
        edit_button_screenreader_text
      }
      excess {
        excess_heading
        edit_button_text
        edit_button_screenreader_text
      }
      your_cover_dates {
        your_cover_dates_heading
        edit_button_text
        edit_button_screenreader_text
      }
      your_cover_level {
        your_cover_level_heading
        edit_button_text
        edit_button_screenreader_text
      }
    }
    csPetAboutYouAndYourPet {
      joint_policyholder_banner {
        body_text
        button_text
        button_aria_label
        icon {
          icon_code
        }
      }
    }
  }
`;

const DetailsSection: React.FC<DetailsSectionProps> = ({
  currentQuote,
  getError,
  showValidation,
}) => {
  const {
    csPetCheckYourDetailsDetailsSectionV2: {
      about_you,
      about_your_joint_policyholder,
      about_your_pet,
      additional_information,
      excess,
      your_cover_dates,
      your_cover_level,
    },
    csPetAboutYouAndYourPet: {
      joint_policyholder_banner: {
        body_text: jointPolicyholderBannerBodyText,
        button_text: jointPolicyholderBannerButtonText,
        button_aria_label: jointPolicyholderBannerButtonAriaLabel,
        icon: { icon_code: jointPolicyholderBannerIcon },
      },
    },
  } = useStaticQuery<DetailsSectionCsProps>(query);

  const yourCoverDatesSectionId = 'your-cover-dates-section';
  const yourCoverLevelSectionId = 'your-cover-level-section';
  const aboutYouSectionId = 'about-you-section';
  const yourContactDetailsSectionId = 'your-contact-details-section';
  const aboutYourJointPolicyholderSectionId = 'about-your-joint-policyholder-section';
  const aboutYourPetSectionId = 'about-your-pet-section';
  const additionalInformationSectionId = 'additional-information-section';
  const excessSectionId = 'excess-section';

  const quoteReference = useQuoteReference();
  const [customerDetails] = useCustomerDetails();
  const [
    jointPolicyholderDetails,
    updateJointPolicyholderDetails,
  ] = useJointPolicyholderDetails();
  const petsDetails = usePetSections();
  const petsExcess = useExcessSections();

  const [showJointPolicyholderForm, setShowJointPolicyholderForm] = useState<boolean>(
    jointPolicyholderDetails.includeJointPolicyholder
  );

  const displayJointPolicyholderWarningPanel =
    customerDetails.customerFirstName !== '' &&
    customerDetails.customerFirstName ===
      jointPolicyholderDetails.jointPolicyholderFirstName &&
    customerDetails.customerLastName !== '' &&
    customerDetails.customerLastName ===
      jointPolicyholderDetails.jointPolicyholderLastName &&
    !areDateValuesEqual(
      addLeadingZerosToDateValue(customerDetails.customerDob),
      initialDateValue
    ) &&
    areDateValuesEqual(
      addLeadingZerosToDateValue(customerDetails.customerDob),
      jointPolicyholderDetails.jointPolicyholderDob
    );

  const jointPolicyholderSectionDetails: Detail[] = [
    createDetail('', undefined, jointPolicyholderBannerBodyText, undefined),
  ];

  useEffect(() => {
    if (jointPolicyholderDetails.includeJointPolicyholder) {
      setShowJointPolicyholderForm(true);
    }
  }, [jointPolicyholderDetails]);

  return (
    <>
      <DetailGroupSectionContainer
        aria-labelledby={yourCoverDatesSectionId}
        id={yourCoverDatesSectionId}>
        <QuoteReferenceWrapper>
          <ParagraphLargeSpan>{quoteReference.label} </ParagraphLargeSpan>
          <BoldParagraphLargeSpan>{quoteReference.value}</BoldParagraphLargeSpan>
        </QuoteReferenceWrapper>
        <StyledHeading>{your_cover_dates.your_cover_dates_heading}</StyledHeading>
        <StyledCoverStartDate pageTitle={PageTitle.CheckYourDetails} />
      </DetailGroupSectionContainer>
      <DetailGroupSection
        sectionHeading={your_cover_level.your_cover_level_heading}
        sectionId={yourCoverLevelSectionId}
        details={useCoverLevelSection()}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit cover level');
          navigate(`${quoteAndBuyRoutes.quoteSummary}`);
        }}
        editButtonLabel={your_cover_level.edit_button_text}
        editButtonScreenreaderText={your_cover_level.edit_button_screenreader_text}
      />
      {currentQuote?.petInfos?.map((petInfo, index) => (
        // Index is the unique identifier for pet info
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <DetailGroupSection
            sectionHeading={petNameReplacer(
              trimEnd(petInfo.petName),
              excess.excess_heading
            )}
            sectionId={`${excessSectionId}[${index}]`}
            details={petsExcess[index]}
            editButtonOnClick={() => {
              trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit pet excess');
              navigate(`${quoteAndBuyRoutes.quoteSummary}#petExcess[${index}]-container`);
            }}
            editButtonLabel={petNameReplacer(
              trimEnd(petInfo.petName),
              excess.edit_button_text
            )}
            editButtonScreenreaderText={excess.edit_button_screenreader_text}
            piiOptions={{ headingIsPii: false, detailLabelsArePii: false }}
          />
        </Fragment>
      ))}
      {currentQuote?.petInfos?.map((petInfo, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <DetailGroupSection
            sectionHeading={petNameReplacer(
              petInfo.petName,
              about_your_pet.about_your_pet_heading
            )}
            sectionId={`${aboutYourPetSectionId}[${index}]`}
            details={petsDetails[index]}
            editButtonOnClick={() => {
              trackTextButtonClick(PageTitle.CheckYourDetails, 'edit pet details');
              navigate(`${quoteAndBuyRoutes.aboutYouAndYourPet}#petDetails[${index}]`);
            }}
            editButtonLabel={petNameReplacer(
              trimEnd(petInfo.petName),
              about_your_pet.edit_button_text
            )}
            editButtonScreenreaderText={about_your_pet.edit_button_screenreader_text}
            piiOptions={{ headingIsPii: false, detailLabelsArePii: false }}
          />
        </Fragment>
      ))}
      <DetailGroupSection
        sectionHeading={about_you.about_you_heading}
        sectionId={aboutYouSectionId}
        details={useAboutYouSection()}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit about you');
          navigate(`${quoteAndBuyRoutes.aboutYouAndYourPet}#about-you-section`);
        }}
        editButtonLabel={about_you.edit_button_text}
        editButtonScreenreaderText={about_you.edit_button_screenreader_text}
        piiOptions={{ detailValuesArePii: true }}
      />
      <CheckContactDetailsForm
        getError={getError}
        showValidation={showValidation}
        sectionId={yourContactDetailsSectionId}
      />
      {showJointPolicyholderForm ? (
        <DetailGroupSectionContainer
          id={aboutYourJointPolicyholderSectionId}
          aria-labelledby={aboutYourJointPolicyholderSectionId}>
          <StyledHeading>
            {about_your_joint_policyholder.about_your_joint_policyholder_heading}
          </StyledHeading>
          <StyledJointPolicyholderForm
            removeJointPolicyholderButtonOnClick={() =>
              setShowJointPolicyholderForm(false)
            }
            formValidation={{ getError, showValidation }}
            displayWarningPanel={displayJointPolicyholderWarningPanel}
          />
        </DetailGroupSectionContainer>
      ) : (
        <DetailGroupSection
          sectionHeading={
            about_your_joint_policyholder.about_your_joint_policyholder_heading
          }
          sectionId={aboutYourJointPolicyholderSectionId}
          details={jointPolicyholderSectionDetails}
          editButtonOnClick={() => {
            trackTextButtonClick(
              PageTitle.CheckYourDetails,
              jointPolicyholderBannerButtonText
            );
            setShowJointPolicyholderForm(true);
            if (!jointPolicyholderDetails.includeJointPolicyholder) {
              updateJointPolicyholderDetails({ includeJointPolicyholder: true });
            }
          }}
          editButtonLabel={jointPolicyholderBannerButtonText}
          editButtonScreenreaderText={jointPolicyholderBannerButtonAriaLabel}
          editButtonIcon={jointPolicyholderBannerIcon}
          piiOptions={{ detailValuesArePii: true }}
        />
      )}
      <DetailGroupSection
        sectionHeading={additional_information.additional_information_heading}
        sectionId={additionalInformationSectionId}
        details={useAdditionalInformationSection()}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit additional information');
          navigate(`${quoteAndBuyRoutes.aboutYouAndYourPet}#numberOfPetsInHousehold`);
        }}
        editButtonLabel={additional_information.edit_button_text}
        editButtonScreenreaderText={additional_information.edit_button_screenreader_text}
        piiOptions={{ detailValuesArePii: false }}
      />
    </>
  );
};

export default DetailsSection;
