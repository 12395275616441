import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { isPet9YearsOrOlderOnDate } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import { trimEnd } from 'lodash';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { formatStringInPoundsWithSign } from 'helpers/poundsHelpers';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsExcessSectionProps = {
  csPetCheckYourDetailsDetailsSectionV2: {
    excess: {
      under_nine_excess: string;
      under_nine_explanation: {
        essential: string;
        standard_plus_premier: string;
      };
      over_nine_excess: string;
      over_nine_explanation: {
        essential: string;
        standard_plus_premier: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      excess {
        under_nine_excess
        under_nine_explanation {
          essential
          standard_plus_premier
        }
        over_nine_excess
        over_nine_explanation {
          essential
          standard_plus_premier
        }
      }
    }
  }
`;

const useExcessSection = (): Detail[][] => {
  const {
    csPetCheckYourDetailsDetailsSectionV2: {
      excess: {
        under_nine_excess,
        under_nine_explanation,
        over_nine_excess,
        over_nine_explanation,
      },
    },
  } = useStaticQuery<CsExcessSectionProps>(query);
  const quote = useCurrentQuote();
  const petInfos = quote?.petInfos ?? undefined;
  if (petInfos === undefined) {
    return [];
  }

  const coverStartDate = quote.policyInfo
    ? new Date(quote.policyInfo.coverStartDate)
    : new Date();

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  const createPetDetails = (petInfo: QuoteResponsePetInfo): Detail => {
    const product = quote?.quoteOptions && getProductFromQuoteOptions(quote.quoteOptions);
    const isEssential = product === Product.Essential;

    if (isPet9YearsOrOlderOnDate(petInfo.dob, coverStartDate)) {
      const excessExplanationText = isEssential
        ? over_nine_explanation.essential
        : over_nine_explanation.standard_plus_premier;

      return createDetail(
        petNameReplacer(trimEnd(petInfo.petName), over_nine_excess),
        formatStringInPoundsWithSign(petInfo.voluntaryExcessAmount),
        plainTextPlaceholderReplacer(excessExplanationText)
      );
    }

    const excessExplanationText = isEssential
      ? under_nine_explanation.essential
      : under_nine_explanation.standard_plus_premier;

    return createDetail(
      petNameReplacer(trimEnd(petInfo.petName), under_nine_excess),
      formatStringInPoundsWithSign(petInfo.voluntaryExcessAmount),
      plainTextPlaceholderReplacer(excessExplanationText)
    );
  };

  return petInfos.map((petInfo): Detail[] => [createPetDetails(petInfo)]);
};

export default useExcessSection;
