import { graphql, useStaticQuery } from 'gatsby';
import { formatLongDateWithOrdinalSuffix } from 'helpers/dateHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsJointPolicyholderSectionProps = {
  csPetCheckYourDetailsDetailsSectionV2: {
    about_your_joint_policyholder: {
      date_of_birth: string;
      first_name: string;
      last_name: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      about_your_joint_policyholder {
        date_of_birth
        first_name
        last_name
      }
    }
  }
`;

const useJointPolicyholderSection = (): Detail[] => {
  const {
    csPetCheckYourDetailsDetailsSectionV2: {
      about_your_joint_policyholder: { date_of_birth, first_name, last_name },
    },
  } = useStaticQuery<CsJointPolicyholderSectionProps>(query);
  const quote = useCurrentQuote();
  const jointPolicyholderInfo = quote.policyInfo?.includeJoinPolicyHolder
    ? quote.policyInfo.joinPolicyHolder
    : undefined;
  if (jointPolicyholderInfo === undefined) {
    return [];
  }

  return [
    createDetail(first_name, jointPolicyholderInfo.firstName),
    createDetail(last_name, jointPolicyholderInfo.lastName),
    createDetail(
      date_of_birth,
      formatLongDateWithOrdinalSuffix(jointPolicyholderInfo.dob)
    ),
  ];
};

export default useJointPolicyholderSection;
