import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import { Declaration } from 'helpers/useDeclarations';
import { AssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { CsAsset, CsHero } from 'types/contentStack';
import { useAggregatorSectionProps } from './useAggregatorSectionProps';
import { useDeclarationSectionProps } from './useDeclarationSectionProps';
import { useDetailsSectionProps } from './useDetailsSectionProps';
import { useDocumentSectionProps } from './useDocumentSectionProps';
import { useKeepingYouInformedSectionProps } from './useKeepingYouInformedSectionProps';
import { usePricingOverviewSectionProps } from './usePricingOverviewSectionProps';
import { useProductFeatureSectionProps } from './useProductFeatureSectionPdfProps';
import { useSummaryOfNeedsSectionProps } from './useSummaryOfNeedsSectionProps';

type PdfHeroContent = {
  csPetCheckYourDetails: {
    hero: CsHero;
    edit_info_intro_text: string;
  };
  csHeroHeader: {
    ms_bank_logo: CsAsset | null;
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetails {
      hero {
        heading
        subheading
      }
      edit_info_intro_text
    }
    csHeroHeader {
      ms_bank_logo {
        ...CsAsset
      }
    }
  }
`;

export const useCheckYourDetailsPdf = (
  quote: CurrentQuote | null,
  declarations: Declaration[],
  assumptionsAgreement: AssumptionsAgreement,
  keepInformedAnswer: boolean | undefined,
  showAssumptions: boolean
): (() => Promise<void>) => {
  const {
    csPetCheckYourDetails: { hero, edit_info_intro_text },
    csHeroHeader: { ms_bank_logo },
  } = useStaticQuery<PdfHeroContent>(query);
  const theme = useTheme();

  const detailsSectionProps = useDetailsSectionProps();
  const documentSectionProps = useDocumentSectionProps();
  const declarationSectionProps = useDeclarationSectionProps(declarations);
  const productFeaturesSectionProps = useProductFeatureSectionProps();
  const pricingOverviewSectionProps = usePricingOverviewSectionProps();
  const summaryOfNeedsSectionProps = useSummaryOfNeedsSectionProps();
  const aggregatorSectionProps = useAggregatorSectionProps(
    assumptionsAgreement,
    showAssumptions
  );
  const keepingYouInformedSectionProps = useKeepingYouInformedSectionProps(
    keepInformedAnswer
  );

  const msBankLogo = processImageAsset(ms_bank_logo);
  const heroProps = {
    heading: hero.heading,
    subheading: hero.subheading,
    logoUrl: msBankLogo?.url,
  };
  const editInfoProps = {
    editInfoText: edit_info_intro_text,
  };

  /* istanbul ignore if */
  if (!quote) {
    return () => Promise.resolve();
  }

  const props = {
    quote,
    detailsSectionProps,
    documentSectionProps,
    declarationSectionProps,
    pricingOverviewSectionProps,
    productFeaturesSectionProps,
    summaryOfNeedsSectionProps,
    aggregatorSectionProps,
    keepingYouInformedSectionProps,
    heroProps,
    editInfoProps,
    theme,
  };

  return async () => {
    const { generateCheckYourDetailsPdf } = await import(
      /* webpackChunkName: "check-details-pdf" */ '.'
    );
    return generateCheckYourDetailsPdf(props);
  };
};
