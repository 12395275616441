import { ContentSpacing } from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer/styles';
import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { GridItemWrapper } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  fonts,
  heavyFontWeight,
} from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import { StyledRadioInput } from 'components/BooleanRadioInput/styles';
import Declaration from 'components/Declaration';
import { QuestionFieldWithoutBottomMargin } from 'components/Declaration/styles';
import RichTextWithModal from 'components/RichTextWithModal';

export const RichTextWithModalAndMarginBottom = styled(RichTextWithModal)`
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(3)};
  `}

  ${mediaQuery.tabletLandscape`
    p {
      ${fonts.paragraphLarge};
    }
  `}
`;

export const DeclarationWithBottomMargin = styled(Declaration)`
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(4)};
  padding: ${spacing(1)} ${spacing(2)} ${spacing(3)};
  background-color: ${colors.neutral05};
  border: solid 1px ${colors.neutral03};

  ${mediaQuery.tabletPortrait`
    margin-top: 0;
    margin-bottom: ${spacing(3)};
    padding: ${spacing(1)} ${spacing(3)} ${spacing(3)};
  `};

  > ${Grid} {
    width: calc(100% + ${spacing(2)});

    > ${GridItemWrapper} {
      width: calc(100% - ${spacing(2)});
    }
  }

  ${QuestionFieldWithoutBottomMargin} {
    margin-bottom: 0;
    padding: ${({ error, theme }) =>
      error ? 0 : `${spacing(3)({ theme })} ${spacing(2)({ theme })}`};
    background-color: ${colors.neutral08};
    border: ${({ error, theme }) =>
      error ? 'None ' : `solid 1px ${colors.neutral03({ theme })}`};

    ${mediaQuery.tabletPortrait`
      padding: ${({ error }) => (error ? 0 : spacing(3))};
    `};

    ${ContentSpacing} {
      ${mediaQuery.tabletPortrait`
        padding: 0 ${({ error }) => (error ? spacing(1) : '0')};
      `};

      ${mediaQuery.tabletLandscape`
        padding: 0;
      `};

      ${Grid} {
        margin: 0;
        width: 100%;

        ${GridItemWrapper} {
          margin: 0;
          width: 100%;
        }
      }
    }

    ${StyledRadioInput} {
      display: flex;
      flex-direction: column;
      gap: ${spacing(2)};

      ${mediaQuery.tabletPortrait`
        flex-direction: row;
        gap: ${spacing(4)};
      `};

      ${RadioItem} {
        margin-left: 0;
      }
    }
  }
`;

export const RichTextWithModalAndMargin = styled(RichTextWithModal)`
  margin-bottom: ${spacing(3)};

  // EP-3934: <h5>...</h5> tags have been added in Contentstack to selectively
  // change font-weight of certain paragraphs to match Figma design .
  h5 {
    font-weight: ${heavyFontWeight};
  }

  p {
    margin: ${spacing(2)} 0;
  }

  p + p {
    margin: ${spacing(2)} 0;
  }

  ${mediaQuery.tabletLandscape`
    p, li {
     ${fonts.paragraphLarge};
    }
  `}
`;
