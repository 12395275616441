import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import { ProductId } from 'helpers/businessConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { AssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { AggregatorSectionProps } from './AggregatorSection';
import { getAnswerText } from './useDeclarationSectionProps';

type AggregatorSectionContent = {
  csPetAggregators: {
    assumptions: {
      section_heading: string;
      subheading: string;
      confirmation_text: string;
    };
  };
  csPetGlobalConfig: {
    boolean_answer_text: {
      yes: string;
      no: string;
    };
  };
};

const query = graphql`
  query {
    csPetAggregators {
      assumptions {
        section_heading
        subheading
        confirmation_text
      }
    }
    csPetGlobalConfig {
      boolean_answer_text {
        yes
        no
      }
    }
  }
`;

export const useAggregatorSectionProps = (
  assumptionsAgreement: AssumptionsAgreement,
  showAssumptions: boolean
): AggregatorSectionProps | undefined => {
  const {
    csPetAggregators: {
      assumptions: { section_heading, subheading, confirmation_text },
    },
    csPetGlobalConfig: { boolean_answer_text },
  } = useStaticQuery<AggregatorSectionContent>(query);

  const theme = useTheme();
  const quote = useCurrentQuote();

  const assumptions = useAssumptions();

  if (!showAssumptions || quote?.productId === ProductId.DIRECT) {
    return undefined;
  }

  return {
    theme,
    confirmationText: confirmation_text,
    subheading,
    sectionHeading: section_heading,
    assumptions,
    confirmationAnswer: getAnswerText(
      assumptionsAgreement.assumptionsAgreed,
      boolean_answer_text
    ),
  };
};
