import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useTheme } from 'styled-components';
import SectionHeading from 'forms/SectionHeading';
import { PageTitle } from 'helpers/eventTracking';
import { CsDeclaration, Declaration } from 'helpers/useDeclarations';
import {
  DeclarationWithBottomMargin,
  RichTextWithModalAndMargin,
  RichTextWithModalAndMarginBottom,
} from './styles';

type DeclarationSectionProps = {
  sectionHeadingId: string;
  declarations: Declaration[];
  updateHasAgreed: (update: boolean, index: number) => void;
  getError: FieldFunction<{ declarations: Declaration[] }, string | undefined>;
};

type CsDeclarationSection = {
  csPetCheckYourDetailsDeclarationV2: {
    section_heading: string;
    section_description: string;
    declaration: CsDeclaration[];
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetailsDeclarationV2 {
      section_heading
      section_description
      declaration {
        question_missed_error_text
        answered_no_error_text
      }
    }
  }
`;

const DeclarationSection: React.FC<DeclarationSectionProps> = ({
  sectionHeadingId,
  declarations,
  updateHasAgreed,
  getError,
}) => {
  const {
    csPetCheckYourDetailsDeclarationV2: {
      section_heading,
      section_description,
      declaration,
    },
  } = useStaticQuery<CsDeclarationSection>(query);

  const theme = useTheme();

  const getDeclarationErrorMessage = (
    errorType: string | undefined,
    index: number
  ): string => {
    if (errorType === 'missing') return declaration[index]?.question_missed_error_text;
    if (errorType === 'invalid') return declaration[index]?.answered_no_error_text;
    return '';
  };

  return (
    <>
      <SectionHeading heading={section_heading} id={sectionHeadingId} />
      <Grid alignLeft>
        <GridItem desktop={8} tabletLandscape={10}>
          <RichTextWithModalAndMarginBottom
            pageTitle={PageTitle.CheckYourDetails}
            html={section_description}
          />
        </GridItem>
      </Grid>
      {declarations.map((_declaration, index) => (
        <Grid alignLeft key={_declaration.content.body_text}>
          <GridItem desktop={8} tabletLandscape={10}>
            <DeclarationWithBottomMargin
              id={`declaration[${index}]`}
              theme={theme}
              hasAgreed={_declaration.hasAgreed}
              updateHasAgreed={(newValue) => updateHasAgreed(newValue, index)}
              error={getDeclarationErrorMessage(
                getError('declarations', ['hasAgreed', index]),
                index
              )}
              questionId={`declarations[${index}].hasAgreed`}
              questionText={_declaration.content.agreement_question}
              disclaimerText={_declaration.content.disclaimer_text}
              pageTitle={PageTitle.CheckYourDetails}>
              <RichTextWithModalAndMargin
                data-cy={`declaration[${index}]-text`}
                pageTitle={PageTitle.CheckYourDetails}
                html={_declaration.content.body_text}
              />
            </DeclarationWithBottomMargin>
          </GridItem>
        </Grid>
      ))}
    </>
  );
};

export default DeclarationSection;
